import { EnvModel } from '@app/shared/models/config';
import packageInfo from '@root/package.json';

export const environment: EnvModel = {
  name: 'dev',
  appVersion: packageInfo.version,
  appVersionDisplayed: packageInfo.version,
  production: true,
  checkVersion: true,
  signInUrl: 'https://api.pps.dev.skoda-auto.com/access-management/auth/sign-in',
  apiGwUrl: 'https://api.pps.dev.skoda-auto.com',
  helpUrl: 'https://wiki.skoda.vwgroup.com',
  dispensingHubUrl: '/dispensingHub',
  helpSpace: 'PPSSYSHELP',
  accessRequestOverviewUrl: '',
  newAccessRequestUrl: '',
  offsetRefreshToken: 60,
  refreshUserNotificationsInMinutes: 10,
  refreshUserTaskStatisticInMinutes: 10,
  features: {
    '*': false,
    arap: true,
    multiCurrency: true,
    editUserRole: true,
  },
};
