export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refresh_token';
export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token';
export const RETURN_URL_LOCAL_STORAGE_KEY = 'return_url';
export const SCOPE_REPAIR_CURRENT_SHAPE = 'scoper_repair_current_shape';
export const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';
export const CURRENCY_LOCAL_STORAGE_KEY = 'currency';
export const DISPENSING_POINT_ID_LOCAL_STORAGE_KEY = 'dispensing_point_id';
export const USER_TASK_STATS_LAST_UPDATED_DATE_STORAGE_KEY = 'user_task_stats_last_updated_date';
export const USER_NOTIFICATIONS_STATS_LAST_UPDATED_DATE_STORAGE_KEY = 'user_notifications_stats_last_updated_date';
export const JWT_TOKEN_WITH_AUTHORIZE_LEVELS_KEY = 'jwt_token_with_autorize_levels';
