import { inject, Injectable } from '@angular/core';
import { AssignedDataGroupListModel, AssignedDataGroupsDataListModel } from '@api/models/access-management';
import { DataGroupInfo } from '@api/models/shared';
import { DataGroupsDataService } from '@api/services/access-management/data-groups-data.service';
import { REFRESH_TOKEN_LOCAL_STORAGE_KEY } from '@core/const/local-storage-keys';
import { AuthService } from '@core/modules/auth';
import { UserDataGroupsModel } from '@core/modules/auth/models';
import { AuthModel } from '@core/modules/auth/models/auth.model';
import { UserModel } from '@core/modules/auth/models/user.model';
import { AuthHTTPService } from '@core/modules/auth/services/auth-http.service';
import { JwtService } from '@core/modules/auth/services/jwt.service';
import { SettingsStoreService } from '@core/services/settings-store.service';
import { TranslationService } from '@services/translation.service';
import { UserViewSettingsService } from '@services/user-view-settings.service';
import { Observable, of, Subscription, switchMap, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private _timer!: Subscription;

  private readonly _settingsStore = inject(SettingsStoreService);

  constructor(
    private readonly _authHttpService: AuthHTTPService,
    private _jwtService: JwtService,
    private readonly _authService: AuthService,
    private readonly _userViewSettingsService: UserViewSettingsService,
    private readonly _dataGroupsDataService: DataGroupsDataService,
    private readonly _translationService: TranslationService,
  ) {
    this._authService.user$.subscribe((user: UserModel | undefined) => {
      if (!user) {
        this.stopTokenTimer();
      }
    });
  }

  /**
   * Aktualizuje Auth tokeny
   * @returns
   */
  public refreshToken(): Observable<AuthModel | undefined> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    if (!refreshToken) {
      this.stopTokenTimer();
      return of(undefined);
    }

    return this._authHttpService.refreshToken(refreshToken).pipe(
      tap((auth: AuthModel) => {
        this._authService.setAuthToLocalStorage(auth);
      }),
      switchMap(() => {
        return this.initServicesAndStartRefreshTimer();
      }),
    );
  }

  public initServicesAndStartRefreshTimer(): Observable<AuthModel | undefined> {
    return this._settingsStore.init().pipe(
      tap(() => {
        this._userViewSettingsService.init();
        this._translationService.init();
      }),
      switchMap(() => {
        return this._dataGroupsDataService.getAssignedDataGroups();
      }),
      map((dataGroups: AssignedDataGroupsDataListModel) => {
        const groups: UserDataGroupsModel[] =
          dataGroups.data.map((row: AssignedDataGroupListModel) => {
            return {
              id: parseInt(row.id),
              name: row.name,
              dataGroupName: DataGroupInfo.getDataGroupById(parseInt(row.id))!,
            };
          }) || [];

        this._authService.initUser(groups);
        this.startTokenTimer();
        return this._authService.getAuthFromLocalStorage();
      }),
    );
  }

  /**
   * Načte Auth tokeny
   * @param token
   * @returns
   */
  public getToken(token: string): Observable<AuthModel> {
    return this._authHttpService.getToken(token).pipe(
      map((auth: AuthModel) => {
        this._authService.setAuthToLocalStorage(auth);
        return auth;
      }),
    );
  }

  private getTokenRemainingTime(): number {
    const auth = this._authService.getAuthFromLocalStorage();
    if (!auth) {
      return 0;
    }

    const expires = this._jwtService.getTokenExpirationDate(auth.accessToken);
    const remainingTime = expires ? expires.getTime() - Date.now() - 60000 : 0;
    if (remainingTime > 0) {
      return remainingTime;
    } else {
      return 0;
    }
  }

  private startTokenTimer(): void {
    const remaining = this.getTokenRemainingTime();
    const timeout = Math.min(Math.max(remaining, 60 * 1000), 60 * 60 * 1000);
    this._timer = timer(timeout)
      .pipe(tap(() => this.refreshToken().subscribe()))
      .subscribe();
  }

  private stopTokenTimer(): void {
    this._timer?.unsubscribe();
  }
}
