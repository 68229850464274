/**
 * Dostupné kontrolery mikroslužby AccessManagement
 */
export enum AccessManagementController {
  Roles = 'roles',
  RoleGroups = 'roles-groups',
  Auth = 'auth',
  AuthorizeLevels = 'authorize-levels',
  OrganizationalUnits = 'organizational-units',
  DataGroups = 'data-groups',
  Persons = 'persons',
  Users = 'users',
  Releases = 'releases',
}
