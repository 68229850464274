import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ApiServiceName, ClientStorageController } from '@api/enums/controllers';
import {
  ComponentSettingDetailModel,
  CreateComponentSettingsModel,
  EditComponentSettingModel,
  PatchComponentSettingModel,
} from '@api/models/client-storage';
import { ApiService } from '@api/services/api.service';
import { BaseApiService } from '@api/services/base-api.service';
import { SanitizationUtility } from '@core/utils/sanitization-utility';

@Injectable({
  providedIn: 'root',
})
export class ComponentSettingsDataService extends BaseApiService<ApiServiceName.ClientStorage, ClientStorageController.ComponentSettings> {
  constructor(
    override readonly _apiService: ApiService,
    private readonly _httpClient: HttpClient,
  ) {
    super(_apiService, ApiServiceName.ClientStorage, ClientStorageController.ComponentSettings);
  }

  /**
   * Vytvoří nastavení komponenty
   */
  public createComponentSettings(settings: CreateComponentSettingsModel): Observable<ComponentSettingDetailModel[]> {
    settings.settings.forEach(element => {
      element.data = SanitizationUtility.sanitize(element.data);
    });
    return this._httpClient.post<ComponentSettingDetailModel[]>(`${this.baseUrl}`, settings);
  }

  /**
   * Edituje nastavení komponenty
   */
  public editComponentSettings(id: string, editedSetting: EditComponentSettingModel): Observable<ComponentSettingDetailModel> {
    editedSetting.data = SanitizationUtility.sanitize(editedSetting.data);
    return this._httpClient.put<ComponentSettingDetailModel>(`${this.baseUrl}/${id}`, editedSetting);
  }

  /**
   * Částečně edituje nastavení komponenty
   */
  public patchComponentSettings(id: string, editedSetting: PatchComponentSettingModel): Observable<ComponentSettingDetailModel> {
    return this._httpClient.patch<ComponentSettingDetailModel>(`${this.baseUrl}/${id}`, editedSetting);
  }

  /**
   * Načte detail nastavení komponenty
   * @param id Id nastavení
   * @returns Detail nastavení
   */
  public getComponentSettingDetail(id: string): Observable<ComponentSettingDetailModel> {
    return this._httpClient.get<ComponentSettingDetailModel>(`${this.baseUrl}/${id}`).pipe(
      map((componentSetting: ComponentSettingDetailModel) => {
        componentSetting.data = SanitizationUtility.desanitize(componentSetting.data);
        return componentSetting;
      }),
    );
  }

  /**
   * Smaže nastavení komponenty
   * @param settingIds Pole IDček nastavneí
   * @returns Pole ID smazaných nastavení
   */
  public deleteComponentSettings(settingIds: string[]): Observable<string[]> {
    const params = new HttpParams({
      fromObject: {
        ids: settingIds,
      },
    });
    return this._httpClient.delete<string[]>(`${this.baseUrl}`, { params });
  }
}
