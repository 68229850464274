/**
 * Dostupné kontrolery mikroslužby Economics
 */
export enum EconomicsController {
  HourRates = 'api/hour-rates',
  Listbooks = 'api/listbooks',
  WageTariffs = 'api/wage-tariffs',
  Currencies = 'api/currencies',
  CurrencyRates = 'api/currency-rates',
  Exchange = 'api/exchange',
  Settings = 'api/settings',
  EconomicCenters = 'list-books/book/economiccenters',
  CostCenters = 'list-books/book/costcenters',
  StatisticOrders = 'list-books/book/statistic-orders',
  OrganizationUnits = 'api/organization-units',
  Workflows = 'api/workflows',
  Teams = 'api/team',
  People = 'api/person',
  ExpenseAccountingDates = 'expense-accounting-dates',
}
