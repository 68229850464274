export class AuthModel {
  accessToken!: string;
  jwtTokenWithAutorizeLevels!: string;
  refreshToken: any;

  constructor(auth?: any) {
    this.setAuth(auth);
  }

  setAuth(auth: any) {
    this.accessToken = auth.accessToken;
    this.jwtTokenWithAutorizeLevels = auth.jwtTokenWithAutorizeLevels;
    this.refreshToken = auth.refreshToken;
  }
}
