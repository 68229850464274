import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';
import { TimeTrackerData, TimeTrackerService } from '@app/shared/modules/log-work/time-tracker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseApiService } from '@core/api/services/base-api.service';
import { ApiServiceName } from '@core/api/enums/controllers';
import { ManufactoryControllerEnum } from '@core/api/enums/controllers/manufactory-controller.enum';
import { ApiService } from '@core/api/services/api.service';
import { DailyPlanDetailModel, OperationStateModel } from '@app/features/manufactory/models/daily-plans.model';

export enum ManufacturingOperationState {
  Ready = '1',
  Start = '3',
  Continue = '6',
  Pause = '4',
  Stop = '8',
}

@Injectable({
  providedIn: 'root',
})
export class DailyPlanStateService extends BaseApiService<ApiServiceName.Manufactory, ManufactoryControllerEnum.DailyPlans> {
  private readonly timeTrackerService = inject(TimeTrackerService);
  private readonly destroyRef = inject(DestroyRef);

  private data!: TimeTrackerData | null;

  constructor(
    protected override readonly _apiService: ApiService,
    private readonly _httpClient: HttpClient,
  ) {
    super(_apiService, ApiServiceName.Manufactory, ManufactoryControllerEnum.DailyPlans);

    this.timeTrackerService.trackerData$
      .pipe(
        filter(data => data?.triggeredFrom === 'daily-plan'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(data => {
        this.data = data;
      });

    this.timeTrackerService.onStart$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(param => this.getCurrentOperationState(param)),
        switchMap(({ operation, param }) =>
          this.updateOperationState(
            operation!.id!,
            this.getNextOperationState(operation?.state as unknown as OperationStateModel, param ? 'continue' : 'start'),
          ),
        ),
      )
      .subscribe();

    this.timeTrackerService.onPause$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(param => this.getCurrentOperationState(param)),
        switchMap(({ operation }) =>
          this.updateOperationState(
            operation!.id!,
            this.getNextOperationState(operation?.state as unknown as OperationStateModel, 'pause'),
          ),
        ),
      )
      .subscribe();

    this.timeTrackerService.onStop$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(param => this.getCurrentOperationState(param)),
        switchMap(({ operation }) =>
          this.updateOperationState(operation!.id!, this.getNextOperationState(operation?.state as unknown as OperationStateModel, 'stop')),
        ),
      )
      .subscribe();
  }

  getCurrentOperationState(param?: unknown): Observable<{ operation: DailyPlanDetailModel['operation']; param: unknown }> {
    return this._httpClient
      .get<DailyPlanDetailModel>(`${this.baseUrl}/${this.data?.meta?.['dailyPlanId']}`)
      .pipe(map(dailyPlan => ({ operation: dailyPlan.operation, param })));
  }

  getNextOperationState(state: OperationStateModel | undefined, trigger: 'start' | 'continue' | 'pause' | 'stop') {
    if (!state) return '';
    if (trigger === 'start') {
      if (state.code === ManufacturingOperationState.Ready && state.availableStateCodes?.includes(ManufacturingOperationState.Start)) {
        return ManufacturingOperationState.Start;
      }
      if (state.code === ManufacturingOperationState.Pause && state.availableStateCodes?.includes(ManufacturingOperationState.Continue)) {
        return ManufacturingOperationState.Continue;
      }
    }
    if (trigger === 'continue') {
      if (state.code === ManufacturingOperationState.Pause && state.availableStateCodes?.includes(ManufacturingOperationState.Continue)) {
        return ManufacturingOperationState.Continue;
      }
    }
    if (trigger === 'pause') {
      if (
        (state.code === ManufacturingOperationState.Start || state.code === ManufacturingOperationState.Continue) &&
        state.availableStateCodes?.includes(ManufacturingOperationState.Pause)
      ) {
        return ManufacturingOperationState.Pause;
      }
    }
    if (trigger === 'stop') {
      if (
        (state.code === ManufacturingOperationState.Start || state.code === ManufacturingOperationState.Continue) &&
        state.availableStateCodes?.includes(ManufacturingOperationState.Stop)
      ) {
        return ManufacturingOperationState.Stop;
      }
    }
    return '';
  }

  updateOperationState(id: string, stateCode: string) {
    const operationUrl = this._apiService.getControllerApiUrl(ApiServiceName.Manufactory, ManufactoryControllerEnum.Operations);
    return this._httpClient.put<any>(`${operationUrl}/${id}/state`, { stateCode });
  }
}
