import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessManagementController, ApiServiceName } from '@api/enums/controllers';
import { ApiService } from '@api/services/api.service';
import { BaseApiService } from '@api/services/base-api.service';
import { AuthModel } from '@core/modules/auth/models/auth.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService extends BaseApiService<ApiServiceName.AccessManagement, AccessManagementController.Auth> {
  constructor(
    private http: HttpClient,
    override readonly _apiService: ApiService,
  ) {
    super(_apiService, ApiServiceName.AccessManagement, AccessManagementController.Auth);
  }

  /**
   * Vrátí Auth tokeny
   * @param token
   * @returns
   */
  public getToken(token: string): Observable<AuthModel> {
    return this.http.get<any>(`${this.baseUrl}/get-token?token=${token}`).pipe(
      map((res: any) => {
        return new AuthModel({
          accessToken: res.accessToken,
          jwtTokenWithAutorizeLevels: res.jwtTokenWithAutorizeLevels,
          refreshToken: res.refreshToken.tokenString,
        });
      }),
    );
  }

  /**
   * Vrátí aktualizované Auth tokeny
   * @param refreshToken
   * @returns
   */
  public refreshToken(refreshToken: string): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${this.baseUrl}/refresh-token`, { refreshToken });
  }

  public logout(): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/log-out`);
  }
}
